<template>
  <div>
  <div v-if="!isMobile" style="max-width: 1200px;margin:0 auto">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{path: '/robot'}" :replace="false">{{ items.robot_type == 'bn'?'Binance':'OKX' }}</el-breadcrumb-item>
      <el-breadcrumb-item>{{ $t('robot.strategy_details') }}<span v-if="items.rank" style="display: inline-block;margin-left: 10px">(NO.{{items.rank<10?'0'+items.rank:items.rank}})</span></el-breadcrumb-item>
    </el-breadcrumb>

    <el-card style="margin-top: 12px" shadow="hover">
      <div class="detail_title">
        <img src="../../assets/img/robot/robot_strategy/strategy_mw.svg">
        <span>{{ items.coin_one }}
          <div v-if="items.after_space != 0" style="position:relative;vertical-align: middle">
            <span style="display:block;vertical-align:middle;font-size: 18px;margin-top:0px;color: #AB54DB">⇀</span>
            <span class="title-array" v-if="items.front_space != 0" style="display: block;color:#FF4D4F;font-size: 18px;transform: scale(-1.0);height: 5px;">⇀</span>
          </div>
          <span class="title-array" v-if="items.after_space == 0" style="display: inline-block !important;color:#AB54DB;font-size: 18px;transform: scale(-1.0);height: 15px;">⇀</span> {{ items.coin_two }}</span>
        <span>{{ $t('robot.semi') }}：{{ $t('robot.grid_strategy') }}</span>
        <img v-if="robotState == '1' && items.state == 0" style="width: 20px; margin-left: 16px" src="../../assets/img/robot/running.gif">
        <span>{{ $t('robot.create_time') }} {{ items.add_time }}</span>
        <div>
          <el-button v-if="items.state <= 1" class="detail_title_button_w" @click="delet(items)">{{ $t('robot.del_strategy') }}</el-button>
          <el-button v-if="items.state == 0" class="detail_title_button_b" @click="startAndStopThis(items, 2)">{{ $t('robot.pause_strategy') }}</el-button>
          <el-button v-if="items.state == 1" class="detail_title_button_b" style="background-color: #52C41A" @click="startAndStopThis(items, 1)">{{ $t('robot.open_strategy') }}</el-button>
        </div>
      </div>
    </el-card>

    <el-card class="total_revenue" style="margin-top: 0px;position: relative" shadow="hover">
      <div class="total_revenue_content" style="width: 100%">
        <div>
          <div style="">
            <span style="margin-bottom: 8px;display: block">{{ $t('robot.Total_revenue') }}({{ details.coinTwo }})</span>
            <span style="font-size: 16px;font-family: PingFangSC-Semibold, PingFang SC;font-weight: 600;color: #1E2329;">{{ details.profit_total | roundEightFilter}}</span>
          </div>
          <div class="">
            <span style="margin-bottom: 8px">{{ $t('robot.yield') }} | {{ $t('robot.total_annualization') }}</span>
            <span v-if="details.profit_rate >= 0" style="font-size: 16px;font-family: PingFangSC-Semibold, PingFang SC;font-weight: 600;color: #52C41A;">{{ details.profit_rate | roundEightFilter }}% | {{ details.profit_rate_year | roundEightFilter }}%</span>
            <span v-if="details.profit_rate < 0" style="font-size: 16px;font-family: PingFangSC-Semibold, PingFang SC;font-weight: 600;color: #FF4D4F;">{{ details.profit_rate | roundEightFilter }}% | {{ details.profit_rate_year | roundEightFilter }}%</span>
          </div>
        </div>
        <div style="width: 97%;height: 1px;background: #EAECEF;margin: 20px 3% 20px 0"></div>
        <div style="width: 100%; position:relative;">
<!--          <div style="display: inline-block;width: 27%;vertical-align: middle;">-->
          <div style="display: inline-block;width: 33%;vertical-align: middle;">
            <span style="margin-bottom: 8px">{{ $t('robot.grid_profit') }}</span>
            <span class="total_revenue_content_bottom" style="">{{ details.profit_all | roundEightFilter }} {{ details.coinTwo }}</span>
          </div>
          <div style="display: inline-block;width: 33%;vertical-align: middle;">
            <span style="margin-bottom: 8px">{{ $t('robot.floating_profit_loss') }}</span>
            <span class="total_revenue_content_bottom" style="">{{ details.profit_loss | roundEightFilter }} {{ details.coinTwo }}</span>
          </div>
          <div style="display: inline-block;width: 33%;vertical-align: middle;">
            <span style="margin-bottom: 8px">{{ $t('robot.investment_amount') }}</span>
            <span class="total_revenue_content_bottom" style="">{{ details.all_space | roundEightFilter }} {{ details.coinTwo }}</span>
          </div>
<!--          <el-button class="detail_title_button_w" style="width: 16%;margin-right: 3%;vertical-align: middle;">查看排行</el-button>-->
        </div>
      </div>
<!--      <div class="total_revenue_content" style="height:170px;border-left: 1px #EAECEF solid;padding-left: 1%;">-->
<!--        <charts-line></charts-line>-->
<!--      </div>-->
    </el-card>

    <el-card class="total_revenue" style="margin-top: 0px;position: relative" shadow="hover">
      <div class="total_revenue_content" style="width: 100%">
        <span style="font-size: 14px;font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;color: #1E2329;">{{ $t('robot.strategy_details') }}</span>
        <div style="width: 100%;height: 1px;background: #EAECEF;margin: 16px 3% 16px 0"></div>
        <div style="width: 100%; position:relative;background-color: #F4F8FF;border-radius: 2px;padding: 17px 0px">
          <div style="display: inline-block;width: 48%;margin-left:2%;vertical-align: middle;">
            <span style="margin-bottom: 8px;display:inline-block;">{{ $t('robot.currently_held_assets') }}</span>
            <span class="total_revenue_content_bottom" style="display:inline-block;margin-left: 10px">{{ details.current_asset }}</span>
          </div>
          <div style="display: inline-block;width: 50%;vertical-align: middle;">
            <span style="margin-bottom: 8px;display:inline-block;">{{ $t('robot.initial_held_assets') }}</span>
            <span class="total_revenue_content_bottom" style="display:inline-block;margin-left: 10px">{{ details.initial_asset }}</span>
          </div>
        </div>
        <div class="celue_detail_base_set">
<!--          <div style="display:inline-block;width: 46%;">-->
          <div style="display:inline-block;width: 100%;">
            <span style="display:block;font-size: 14px;font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;color: #1E2329;">{{ $t('robot.basic_settings') }}</span>
<!--            <div style="display: inline-block;margin-top: 16px;width: unset">-->
<!--            <div style="display: flex; justify-content: space-between;margin-top: 16px;width: unset">-->
<!--              <div style="display: block;width: unset">-->
<!--                <span style="display: inline-block">價格區間：</span>-->
<!--                <span style="color: #1E2329">{{ details.floor_price }}-{{ details.ceiling_price }} USDT</span>-->
<!--              </div>-->
<!--              <div style="text-align: left;margin-top: 12px;display: block;width: unset">-->
<!--                <span style="display: inline-block">網格模式：</span>-->
<!--                <span style="color: #1E2329">{{details.grid_mode==0?'等差':'等比'}}</span>-->
<!--              </div>-->
<!--              <div style="margin-top: 12px;display: block;width: unset">-->
<!--                <span style="display: inline-block">預期單網格利潤率：</span>-->
<!--                <span style="color: #1E2329">{{ details.min_profit }}%-{{ details.max_profit }}%</span>-->
<!--              </div>-->
<!--              <div style="margin-top: 12px;display: block;width: unset">-->
<!--                <span style="display: inline-block">挂單成交次數：</span>-->
<!--                <span style="color: #1E2329">{{ details.deal_times }}</span>-->
<!--              </div>-->
<!--            </div>-->
<!--&lt;!&ndash;            <div style="display: inline-block;margin-top: 16px;margin-left: 20px;width: unset">&ndash;&gt;-->
<!--            <div style="display: flex; justify-content: space-between;margin-top: 16px;margin-left: 20px;width: unset">-->
<!--              <div style="text-align: left">-->
<!--                <span style="display: inline-block">網格數量：</span>-->
<!--                <span style="color: #1E2329">{{ details.grid_number }}</span>-->
<!--              </div>-->
<!--              <div style="text-align: left;margin-top: 12px">-->
<!--                <span style="display: inline-block">單網格金額：</span>-->
<!--                <span style="color: #1E2329">{{ details.grid_size }} {{ details.coinOne }}</span>-->
<!--              </div>-->
<!--              <div style="margin-top: 12px;text-align: left">-->
<!--                <span style="display: inline-block">策略開啓時價格：</span>-->
<!--                <span style="color: #1E2329">{{ details.start_price }}{{ details.coinTwo }}</span>-->
<!--              </div>-->
<!--              <div style="margin-top: 12px;text-align: left">-->
<!--                <span style="display: inline-block">網格縂套利次數：</span>-->
<!--                <span style="color: #1E2329">{{ details.profit_times }}</span>-->
<!--              </div>-->
<!--            </div>-->

            <div style="display: flex; justify-content: space-between;margin-top: 16px;width: unset;width: 100%">
              <div style="display: block;margin-top: 12px;width: 11.1%">
                <span style="display: block">{{ $t('robot.Price_range') }}</span>
                <span style="color: #1E2329;margin-top: 5px">{{ details.floor_price | roundEightFilter }}-{{ details.ceiling_price | roundEightFilter }} {{ details.coinTwo }}</span>
              </div>
              <div style="text-align: left;margin-top: 12px;display: block;padding:0;width: 11.1%">
                <span style="display: block">{{ $t('robot.grid_mode') }}</span>
                <span style="color: #1E2329;margin-top: 5px">{{details.grid_mode==0?$t('robot.equal_difference'):$t('robot.equal_ratio')}}</span>
              </div>
              <div style="text-align: left;margin-top: 12px;display: block;padding:0;width: 11.1%">
                <span style="display: block">{{ $t('robot.equal_difference_value') }}</span>
                <span style="color: #1E2329;margin-top: 5px">{{details.grid_space | roundEightFilter}}</span>
              </div>
              <div style="margin-top: 12px;display: block;width: 11.1%">
                <span style="display: block">{{ $t('robot.expected_single_grid_profit_margin') }}</span>
                <span style="color: #1E2329;margin-top: 5px">{{ details.min_profit | roundEightFilter }}%-{{ details.max_profit | roundEightFilter }}%</span>
              </div>
              <div style="margin-top: 12px;display: block;width: 11.1%">
                <span style="display: block">{{ $t('robot.number_of_pending_orders') }}</span>
                <span style="color: #1E2329;margin-top: 5px">{{ details.deal_times }}</span>
              </div>
<!--            </div>-->
            <!--            <div style="display: inline-block;margin-top: 16px;margin-left: 20px;width: unset">-->
<!--            <div style="display: inline-flex; justify-content: space-between;margin-top: 0px;width: 50%;padding-right: 0px">-->
              <div style="text-align: left;margin-top: 12px;width: 11.1%">
                <span style="display: block">{{ $t('robot.grid_num') }}</span>
                <span style="color: #1E2329;margin-top: 5px">{{ details.grid_number }}</span>
              </div>
              <div style="text-align: left;margin-top: 12px;width: 11.1%">
                <span style="display: block">{{ details.after_space>0?$t('robot.single_grid_amount'):$t('robot.single_grid_num') }}</span>
                <span style="color: #1E2329;margin-top: 5px">{{ details.grid_size | roundEightFilter }} {{ details.after_space>0?details.coinTwo: details.coinOne }}</span>
              </div>
              <div style="margin-top: 12px;text-align: left;width: 11.1%">
                <span style="display: block">{{ $t('robot.price_when_strategy_started') }}</span>
                <span style="color: #1E2329;margin-top: 5px">{{ details.start_price | roundEightFilter }}{{ details.coinTwo }}</span>
              </div>
              <div style="margin-top: 12px;text-align: left;width: 11.1%">
                <span style="display: block">{{ $t('robot.total_arbitrage_times_grid') }}</span>
                <span style="color: #1E2329;margin-top: 5px">{{ details.profit_times }}</span>
              </div>
            </div>
          </div>
<!--          <div style="display:inline-flex;width: 18%;border-left: 1px solid #EAECEF;justify-content: center; vertical-align: top">-->
<!--            <div>-->
<!--              <span style="display:block;font-size: 14px;font-size: 14px;font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;color: #1E2329;">止盈止損</span>-->
<!--              <div style="display:block;width:auto;margin-top: 16px;padding-right: 0;text-align: left">-->
<!--                <span>止盈價格：</span>-->
<!--                <span style="color: #1E2329">&#45;&#45;</span>-->
<!--              </div>-->
<!--              <div style="display:block;width:auto;margin-top: 12px;padding-right: 0;text-align: left">-->
<!--                <span>止損價格：</span>-->
<!--                <span style="color: #1E2329">&#45;&#45;</span>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div style="display:inline-block;width: 35%;height:147px;border-left:1px solid #EAECEF;vertical-align: top">-->
<!--              <span style="display:block;font-size: 14px;font-size: 14px;font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;color: #1E2329;margin-left: 66px">馬丁策略</span>-->
<!--            <div style="display: inline-block;margin-top: 16px;margin-left: 66px">-->
<!--              <div style="text-align: left;display: block;width: unset;">-->
<!--                <span>跌多少加倉：</span>-->
<!--                <span style="color: #1E2329">1%</span>-->
<!--              </div>-->
<!--              <div style="margin-top: 12px;display: block;width: unset;text-align:left;padding-right: 0">-->
<!--                <span>最大加倉次數：</span>-->
<!--                <span style="color: #1E2329">8</span>-->
<!--              </div>-->
<!--              <div style="margin-top: 12px;display: block;width: unset;">-->
<!--                <span>觸發價：</span>-->
<!--                <span style="color: #1E2329">&#45;&#45;</span>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div style="display: inline-block;margin-top: 16px;margin-left: 20px">-->
<!--              <div >-->
<!--                <span>賺多少止盈：</span>-->
<!--                <span style="color: #1E2329">1.5%</span>-->
<!--              </div>-->
<!--              <div style="margin-top: 12px">-->
<!--                <span>加倉倍投倍數：</span>-->
<!--                <span style="color: #1E2329">10</span>-->
<!--              </div>-->
<!--              <div style="margin-top: 12px">-->
<!--                <span>漲破多少不再加倉：</span>-->
<!--                <span style="color: #1E2329">94428.7</span>-->
<!--              </div>-->
<!--            </div>-->

<!--          </div>-->
        </div>
      </div>
    </el-card>

    <el-card class="grid_order" style="margin-top: 0px;position: relative" shadow="hover">
      <div class="" style="width: 100%;">
        <div style="display:block;position: relative">
          <span style="display:block;font-size: 14px;font-size: 14px;font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;color: #1E2329">{{ $t('robot.grid_hanging_list') }}</span>
          <div style="width: 100%;height: 1px;background: #EAECEF;margin: 16px 3% 16px 0"></div>
          <span style="position: absolute;left: 0">{{ $t('robot.latest_market_price') }} <span style="color: #1E2329" :style="{color: (details2 > details.start_price) ? '#52C41A' : '#FF4D4D'}">{{details2}} {{details.coinTwo}}</span> | {{ $t('robot.purchase_price') }}<span style="color: #1E2329;"> {{ details.start_price | roundEightFilter }} {{ details.coinTwo }}</span> </span>
          <span style="position: absolute;right: 0">{{ details.after_space>0?$t('robot.single_grid_amount'):$t('robot.single_grid_num') }} <span style="color: #1E2329">{{ details.grid_size | roundEightFilter }} {{ details.after_space>0?details.coinTwo: details.coinOne}}</span></span>
        </div>
        <div style="display: block;position:relative;margin-top: 38px">
          <span style="position:absolute;background-color:#EAF8E3;color: #52C41A;text-align:center;width:67px;height:25px;line-height: 25px;">{{ $t('robot.pay_the_bill') }} {{ buyOrderList.length }}</span>
          <div v-if="buyOrderList.length == 0 && sellOrderList.length == 0" style="position:absolute;background-color: #EAECEF;height:25px;left: 67px;right: 67px;"></div>
          <el-slider v-else class="slider_buy" style="position:absolute;left: 67px;right: 67px;" disabled show-tooltip :value="buyOrderList.length" :max="value_total"></el-slider>
          <span style="position:absolute;background-color: #FFE9E9;color: #FF0000;right: 0;top:0;width: 67px;height:25px;text-align: center;line-height: 25px;">{{ $t('robot.selling_order') }} {{ sellOrderList.length }}</span>
        </div>
        <div class="grid_order_list">
          <div>
            <span style="display: inline-block;width: 20%;text-align: left">{{ $t('robot.number') }}</span>
            <span style="display: inline-block;width: 20%;text-align: left">{{ $t('robot.entrusted_price') }}</span>
            <span style="display: inline-block;width: 20%;text-align: center">{{ $t('robot.trading_up_and_down') }}</span>
            <span style="display: inline-block;width: 20%;text-align: right">{{ $t('robot.entrusted_price') }}</span>
            <span style="display: inline-block;width: 20%;text-align: right">{{ $t('robot.number') }}</span>
          </div>
          <div style="margin-top: 8px">
            <table v-if="buyOrderList.length != 0" class="table" style="width: 49.5%; display: inline-block;height: 160px; overflow-y: scroll; border-color:#EBEEF5"frame=void>
              <tbody style="width: 100%; display: inline-table;">
              <tr v-for="(item, i) in buyOrderList" style="width: 100%;">
                <td style="padding-left: 5px;color: #52C41A">{{ item.side=='buy'? $t('robot.buy'):'' }}{{ i+1 }}</td>
                <td style="padding-left: 38px;">{{ item.price }}</td>
                <td style="text-align: right;padding-right: 1%;">{{ item.buyPrice | roundEightFilter }}%</td>
              </tr>
              </tbody>
            </table>
            <div v-else-if="buyOrderList.length == 0 && sellOrderList.length != 0" style="display: inline-block;text-align: center;line-height: 160px;width: 49.5%;vertical-align: top">{{ $t('robot.no_data') }}</div>
            <div v-else-if="buyOrderList.length == 0 && sellOrderList.length == 0" style="display: inline-block;text-align: center;line-height: 160px;width: 49.5%;vertical-align: top">{{ $t('robot.data_acquisition_in_progress') }}</div>
            <table v-if="sellOrderList.length != 0" class="table" style="width: 49.5%; display: inline-block;position: absolute;right: 10px; border-left:#C7C9CB 1px solid;vertical-align: top;height: 160px; overflow-y: scroll;">
              <tbody style="width: 100%; display: inline-table;">
              <tr v-for="(item, i) in sellOrderList" style="width: 100%;">
                <td style="text-align: left;padding-left: 1%;">{{ item.buyPrice | roundEightFilter }}%</td>
                <td style="text-align:right;padding-right: 38px;">{{ item.price }}</td>
                <td style="text-align:right;color:#FF0000;padding-right: 5px;">{{ item.side=='sell'? $t('robot.sell'):'' }}{{ i+1 }}</td>
              </tr>
              </tbody>
            </table>
            <div v-else-if="buyOrderList.length != 0 && sellOrderList.length == 0" style="display: inline-block;text-align: center;border-left:#C7C9CB 1px solid;line-height: 160px;width: 49.5%;vertical-align: top">{{ $t('robot.no_data') }}</div>
            <div v-else-if="buyOrderList.length == 0 && sellOrderList.length == 0" style="display: inline-block;text-align: center;border-left:#C7C9CB 1px solid;line-height: 160px;width: 49.5%;vertical-align: top">{{ $t('robot.data_acquisition_in_progress') }}</div>
          </div>
        </div>
      </div>
    </el-card>

    <span v-show="details.profit_all<0" style="color: #FF4D4F">* {{ $t('robot.negative_tip') }}</span>

    <el-card class="" shadow="hover">
      <span style="display:block;font-size: 14px;font-size: 14px;font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;color: #1E2329">{{ $t('robot.transaction_record') }}</span>
      <div style="width: 100%;height: 1px;background: #EAECEF;margin: 16px 3% 4px 0"></div>
      <div class="robot-pc">
        <el-table :data="dealOrderList" style="width: 100%">
          <el-table-column align="left" prop="deal_time" :label="$t('robot.transaction_time')"></el-table-column>
          <el-table-column align="center" prop="profitFex" :label="$t('robot.grid_profit')">
            <template slot-scope="scope">
              <span v-if="scope.row.profitFex == '--'" :style="{color: scope.row.profitFex>0?'#52C41A': scope.row.profitFex<0?'#FF4D4F':'#1E2329'}">{{ scope.row.profitFex }}</span>
              <span v-else :style="{color: scope.row.profitFex>0?'#52C41A': scope.row.profitFex<0?'#FF4D4F':'#1E2329'}">{{ scope.row.profitFex}}</span>
            </template>
          </el-table-column>
          <el-table-column align="right" prop="earn" :label="$t('robot.operation')">
            <template slot-scope="scope">
              <span style="color: #AB54DB;font-size: 14px;font-weight: 400;cursor: pointer" @click="showDetails(1,scope.row.buy_order_id, scope.row.sell_order_id)">{{ $t('robot.transaction_details') }}</span>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
            style="text-align: right;margin: 24px"
            layout="prev, pager, next"
            @current-change="getDealOrderListToPage"
            :page-size="10"
            :current-page="pageNum"
            :hide-on-single-page="capitalListTotal<=0"
            :total="capitalListTotal">
        </el-pagination>
      </div>
    </el-card>
    <el-dialog
        class="details_dialog"
        :title="$t('robot.transaction_details')"
        :visible.sync="semi_drawer2"
        width="1100px"
        style="height: auto;overflow: unset;padding-top: 0px">
      <el-divider class="details_pc" style="margin-top: -20px !important;"></el-divider>

      <div class="">
        <div style="font-size: 14px;display: flex; justify-content: space-between">
          <span style="display: inline-block;text-align: left;width: 14.2%;">{{ $t('robot.entrusted_time') }}</span>
          <span style="display: inline-block;text-align: left;width: 14.2%;">{{ $t('robot.transaction_time') }}</span>
          <span style="display: inline-block;text-align: left;width: 14.2%;">{{ $t('robot.trading_direction') }}</span>
          <span style="display: inline-block;text-align: left;width: 14.2%;">{{ $t('robot.transaction_volume') }}</span>
          <span style="display: inline-block;text-align: left;width: 14.2%;">{{ $t('robot.average_transaction_price') }}</span>
          <span style="display: inline-block;text-align: left;width: 14.2%;">{{ $t('robot.number_of_transactions') }}</span>
          <span style="display: inline-block;text-align: left;width: 14.2%;">{{ $t('robot.transaction_fee') }}</span>
        </div>
        <div style="margin-top: 8px">
          <table class="table" style="width: 100%; display: inline-block; border-color:#EBEEF5"frame=void>
            <tbody style="width: 100%; display: inline-table;">
            <tr v-for="(item, i) in DealOrderDetails" style="width: 100%;">
              <td style="text-align: left;width: 14.2%;word-break: break-word">{{ item.create_time }}</td>
              <td style="text-align: left;width: 14.2%;word-break: break-word">{{ item.deal_time }}</td>
              <td v-if="item.side == 'buy'" style="text-align: left;width: 14.2%;color: #52C41A">{{ $t('robot.buy_in') }}</td>
              <td v-if="item.side == 'sell'"style="text-align: left;width: 14.2%;color: #FF4D4F">{{ $t('robot.sell_out') }}</td>
              <td style="text-align: left;width: 14.2%;word-break: break-word">{{ item.total_true | roundEightFilter }} <span style="color: #C7C9CB">({{items.coin_one}})</span></td>
              <td style="text-align: left;width: 14.2%;word-break: break-word">{{ item.avg_price | roundEightFilter }} <span style="color: #C7C9CB">({{items.coin_one}})</span></td>
              <td style="text-align: left;width: 14.2%;word-break: break-word">{{ item.size | roundEightFilter }} <span style="color: #C7C9CB">({{items.coin_two}})</span></td>
              <td style="text-align: left;width: 14.2%;word-break: break-word">-{{ item.fee_true | roundEightFilter }} <span style="color: #C7C9CB">({{item.side == 'buy'?items.coin_two:items.coin_one}})</span></td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>

    </el-dialog>
  </div>
  <div v-else style="">
    <div style="height: 50px;background-color: white;position:relative;">
      <i class="el-icon-arrow-left" style="display:inline-block;font-size: 20px;line-height: 50px;margin-left: 20px" @click="$router.back()"></i>
      <span style="display:inline-block;position: absolute; margin: auto; left: 50%; top: 50%;transform: translate(-50%, -50%);">{{ $t('robot.strategy_details') }}<span v-if="items.rank" style="display: inline-block;margin-left: 5px">(NO.{{items.rank<10?'0'+items.rank:items.rank}})</span></span>
      <img v-if="items.robot_type == 'bn'" style="position: absolute;right: 0;top: 0;width: 20px;height: 22px" src="../../assets/img/robot/binance_sign.svg">
      <img v-if="items.robot_type == 'ok'" style="position: absolute;right: 0;top: 0;width: 20px;height: 22px" src="../../assets/img/robot/okex_sign.svg">
    </div>
    <el-card style="" shadow="hover">
      <div class="detail_title" style="display:inline-block;border-bottom: unset; position: relative;width: 100%;">
<!--        <span style="display:inline-block;font-size: 16px;vertical-align: middle">{{ items.coin_one }} <div v-if="items.after_space != 0" style="position: relative;vertical-align: middle"><span style="display:inline-block;vertical-align:unset;font-size: 18px;line-height:3px;color: #AB54DB">⇀</span><span class="title-array" v-if="items.front_space != 0" style="display: block;color:#FF4D4F;font-size: 18px;transform: scale(-1.0);line-height: 6px; margin: -5px 0 0 0">⇀</span></div><span class="title-array" v-if="items.after_space == 0" style="display: inline-block !important;color:#AB54DB;font-size: 18px;transform: scale(-1.0);height: unset !important;">⇀</span> {{ items.coin_two }}</span>-->
        <span style="display:inline-block;font-size: 16px;vertical-align: middle">{{ items.coin_one }}
          <div v-if="items.after_space != 0" style="display:inline-block;position: relative;vertical-align:middle">
            <span style="display:block;font-size: 18px;color: #AB54DB;padding: 0px;line-height:3px;">⇀</span>
            <span v-if="items.front_space != 0" class="title-array" style="display: block;color:#FF4D4F;font-size: 18px;line-height: 6px;transform: scale(-1.0);padding: 0px; margin: 5px 0 0 0">⇀</span>
          </div>
          <span class="title-array" v-if="items.after_space == 0" style="display: inline-block !important;color:#AB54DB;font-size: 18px;transform: scale(-1.0);height: unset !important;">⇀</span> {{ items.coin_two }}</span>
        <span style="color: #AB54DB;background-color: #F4F8FF;padding: 4px 10px;font-size: 14px">{{ $t('robot.grid_strategy') }}</span>
        <img v-if="robotState == '1' && items.state == 0" style="display:inline-block;width: 20px;height: unset;vertical-align: middle;margin-left: 9px" src="../../assets/img/robot/running.gif">
        <span style="display: block;font-size: 12px;margin-left: 0px;margin-top: 5px">{{ $t('robot.create_time') }} {{ items.add_time }}</span>
        <div style="display: inline-block;position: absolute;right: 0;top: 0">
          <el-button v-if="items.state <= 1" class="detail_title_button_w" style="display: block;margin: 3px;padding: 4px 15px;" @click="delet(items)">{{ $t('robot.del') }}</el-button>
          <el-button v-if="items.state == 0" class="detail_title_button_b" style="display: block;margin: 5px 0 0 3px;padding: 4px 16px;" @click="startAndStopThis(items, 2)">{{ $t('robot.pause') }}</el-button>
          <el-button v-if="items.state == 1" class="detail_title_button_b" style="display: block;margin: 5px 0 0 3px;padding: 4px 16px;background-color: #52C41A" @click="startAndStopThis(items, 1)">{{ $t('robot.start') }}</el-button>
        </div>
      </div>

    </el-card>
    <el-card style="margin-top: 12px;min-height: 500px" shadow="hover">
      <el-tabs style="" v-model="celue_type" @tab-click="celueType">
        <el-tab-pane :label="$t('robot.revenue_details')" name="1"></el-tab-pane>
        <el-tab-pane :label="$t('robot.grid_hanging_list')" name="3"></el-tab-pane>
        <el-tab-pane :label="$t('robot.policy_info')" name="2"></el-tab-pane>
        <el-tab-pane :label="$t('robot.transaction_record')" name="4"></el-tab-pane>
      </el-tabs>
      <div v-if="celue_type == 1" class="" style="position: relative">
        <span style="font-size: 14px">{{ $t('robot.Total_revenue') }}(<span style="color: #72777E">{{ details.coinTwo }}</span>)</span>
        <span style="display:block;margin-top:6px;font-size: 22px;font-weight: 600">{{ details.profit_total | roundEightFilter }}</span>
        <div class="" style="position: absolute;top: 0; right: 0; text-align: right">
          <span style="display:block;font-size: 14px; margin-bottom: 0px;">{{ $t('robot.yield') }} | {{ $t('robot.total_annualization') }}</span>
          <span v-if="details.profit_rate > 0" style="display:block;font-size: 19px;margin-top:6px;font-family: PingFangSC-Semibold, PingFang SC;font-weight: 600;color: #52C41A;padding-right: 0px">{{ details.profit_rate | roundEightFilter }}% | {{ details.profit_rate_year | roundEightFilter }}%</span>
          <span v-if="details.profit_rate == 0" style="display:block;font-size: 19px;margin-top:6px;font-family: PingFangSC-Semibold, PingFang SC;font-weight: 600;color: #1E2329;padding-right: 0px">0.00% | 0.00%</span>
          <span v-if="details.profit_rate < 0" style="display:block;font-size: 19px;margin-top:6px;font-family: PingFangSC-Semibold, PingFang SC;font-weight: 600;color: #FF4D4F;padding-right: 0px">{{ details.profit_rate | roundEightFilter }}% | {{ details.profit_rate_year | roundEightFilter }}%</span>
        </div>
        <div style="width: 100%;height: 1px;background: #EAECEF;margin: 15px 3% 15px 0"></div>
        <div style="font-size: 14px;position:relative;color: #72777E">
          <span style="vertical-align: middle;">{{ $t('robot.grid_profit') }}</span><tooltip :content="$t('robot.grid_profit_tip')"></tooltip>
          <span style="display:inline-block; position:absolute;right:0;top:2px;color: #1E2329">{{ details.profit_all | roundEightFilter }} {{ details.coinTwo }}</span>
        </div>
        <div style="font-size: 14px;position:relative;margin-top: 10px;color: #72777E">
          <span style="vertical-align: middle">{{ $t('robot.floating_profit_loss') }}</span><tooltip :content="$t('robot.floating_profit_loss_tip')"></tooltip>
          <span style="display:inline-block; position:absolute;right:0;top:2px;color: #1E2329">{{ details.profit_loss | roundEightFilter }} {{ details.coinTwo }}</span>
        </div>
        <div style="font-size: 14px;position:relative;margin-top: 10px;color: #72777E">
          <span style="vertical-align: middle">{{ $t('robot.investment_amount') }}</span><tooltip :content="$t('robot.investment_amount_tip')"></tooltip>
          <span style="display:inline-block; position:absolute;right:0;top:2px;color: #1E2329">{{ details.all_space | roundEightFilter }} {{ details.coinTwo }}</span>
        </div>
<!--        <span style="display:block;font-size: 14px;margin-top: 50px">收益變化</span>-->
<!--        <charts-line style="margin-top: 10px"></charts-line>-->
      </div>
      <div v-if="celue_type == 2" class="" style="font-size: 14px">
        <div style="width: 100%; position:relative;background-color: #F4F8FF;border-radius: 2px;padding: 10px 0px">
          <div style="display: inline-block;width: 50%;vertical-align: middle;">
            <span style="margin:0 0px 2px 10px;display:inline-block;color: #72777E;font-weight: 300">{{ $t('robot.currently_held_assets') }}</span>
<!--            <span style="display:inline-block;margin-left: 10px;color:#1E2329;font-weight: 500">1.486021 LTC<br/>200 USDT</span>-->
            <span style="display:inline-block;margin-left: 10px;color:#1E2329;font-weight: 500">{{ details.current_asset }}</span>
          </div>
          <div style="display: inline-block;width: 50%;vertical-align: top;text-align: right">
            <span style="margin-bottom: 2px;display:inline-block;color: #72777E;font-weight: 300;margin-right: 10px">{{ $t('robot.initial_held_assets') }}</span>
<!--            <span style="display:inline-block;color:#1E2329;font-weight: 500">1.486021 LTC<br/>200 USDT</span>-->
            <span style="display:inline-block;color:#1E2329;font-weight: 500;margin-right: 10px">{{ details.initial_asset }}</span>
          </div>
        </div>
        <div>
          <div style="display:inline-block;font-size: 14px;position:relative;margin-top:15px;color: #72777E;width: 55%">
            <span style="display:block;position:relative;vertical-align: middle">{{ $t('robot.Price_range') }}</span>
            <span style="display:block; color: #1E2329">{{ details.floor_price | roundEightFilter }}-{{ details.ceiling_price | roundEightFilter }} {{ details.coinTwo }}</span>
          </div>
          <div style="display:inline-block;font-size: 14px;position:relative;margin-top: 10px;color: #72777E;width: 45%">
            <span style="position:relative;vertical-align: middle">{{ $t('robot.number_of_grids') }}</span>
            <span style="display:block; color: #1E2329">{{ details.grid_number }}</span>
          </div>
        </div>
        <div>
          <div style="display:inline-block;width:55%;font-size: 14px;position:relative;margin-top: 10px;color: #72777E">
            <span style="position:relative;vertical-align: middle">{{ $t('robot.grid_mode') }}</span>
            <span style="display:block; color: #1E2329">{{details.grid_mode==0?$t('robot.equal_difference'):$t('robot.equal_ratio')}}</span>
          </div>
          <div style="display:inline-block;width:45%;font-size: 14px;position:relative;margin-top: 10px;color: #72777E">
            <span style="position:relative;vertical-align: middle">{{ $t('robot.equal_difference_value') }}</span>
            <span style="display:block; color: #1E2329">{{ details.grid_space | roundEightFilter }}</span>
          </div>
        </div>
        <div>
          <div style="display:inline-block;width:55%;font-size: 14px;position:relative;margin-top: 10px;color: #72777E">
            <span style="position:relative;vertical-align: middle">{{ $t('robot.expected_single_grid_profit_margin') }}</span>
            <span style="display:block; color: #1E2329">{{ details.min_profit | roundEightFilter }}%-{{ details.max_profit | roundEightFilter }}%</span>
          </div>
          <div style="display:inline-block;width:45%;font-size: 14px;position:relative;margin-top: 10px;color: #72777E">
            <span style="position:relative;vertical-align: middle">{{ $t('robot.price_when_strategy_started') }}</span>
            <span style="display:block; color: #1E2329">{{ details.start_price | roundEightFilter }} {{ details.coinTwo }}</span>
          </div>
        </div>
        <div>
          <div style="display:inline-block;width:55%;font-size: 14px;position:relative;margin-top: 10px;color: #72777E">
            <span style="position:relative;vertical-align: middle">{{ details.after_space>0?$t('robot.single_grid_amount'):$t('robot.single_grid_num') }}</span>
            <span style="display:block; color: #1E2329">{{ details.grid_size | roundEightFilter }} {{ details.after_space>0?details.coinTwo: details.coinOne }}</span>
          </div>
        </div>
<!--        <div style="width: 100%;height: 1px;background: #EAECEF;margin: 15px 0"></div>-->
<!--        <div style="font-size: 14px;position:relative;margin-top: 10px;color: #72777E">-->
<!--          <span style="position:relative;vertical-align: middle">止盈價格</span>-->
<!--          <span style="display:inline-block; position:absolute;right:0;color: #1E2329">0.5 USDT</span>-->
<!--        </div>-->
<!--        <div style="font-size: 14px;position:relative;margin-top: 10px;color: #72777E">-->
<!--          <span style="position:relative;vertical-align: middle">止損價格</span>-->
<!--          <span style="display:inline-block; position:absolute;right:0;color: #1E2329">0.5 USDT</span>-->
<!--        </div>-->
        <div style="width: 100%;height: 1px;background: #EAECEF;margin: 15px 0"></div>
        <div style="display:inline-block;width:55%;font-size: 14px;position:relative;margin-top: 0px;color: #72777E">
          <span style="position:relative;vertical-align: middle">{{ $t('robot.number_of_pending_orders') }}</span>
          <span style="display:block; color: #1E2329">{{ details.deal_times }}</span>
        </div>
        <div style="display:inline-block;width:45%;font-size: 14px;position:relative;margin-top: 0px;color: #72777E">
          <span style="position:relative;vertical-align: middle">{{ $t('robot.Arbitrage_times') }}</span>
          <span style="display:block; color: #1E2329">{{ details.profit_times }}</span>
        </div>
      </div>
      <div v-if="celue_type == 3" class="" style="width: 100%;">
        <div style="display:block;position: relative;font-size: 14px">
          <span style="display:block;color:#72777E;width: 60%">{{ $t('robot.latest_market_price') }} | {{ $t('robot.purchase_price') }}<span style="display: block;margin-top: 3px;" :style="{color: (details2 > details.start_price) ? '#33B06A' : '#FF4D4D'}">{{details2}} {{details.coinTwo}}<span style="color: #1E2329;"> | {{ details.start_price | roundEightFilter }} {{ details.coinTwo }}</span></span></span>
          <span style="display:inline-block;position: absolute;top:0;right: 0;color:#72777E;text-align: right;width: 40%">{{ details.after_space>0?$t('robot.single_grid_amount'):$t('robot.single_grid_num') }}<span style="color: #1E2329;display: block;color:#1E2329;margin-top: 3px">{{ details.grid_size | roundEightFilter }} {{ details.after_space>0?details.coinTwo: details.coinOne }}</span></span>
        </div>
        <div style="display: block;position:relative;margin-top: 9px">
          <span style="position:absolute;background-color:#EAF8E3;color: #52C41A;font-size:12px;height:22px;width: 60px;line-height:22px;text-align:center;padding: 0;">{{ $t('robot.pay_the_bill') }} {{ value_trade }}</span>
          <div v-if="buyOrderList.length == 0 && sellOrderList.length == 0" style="position:absolute;background-color: #EAECEF;height:22px;left: 60px;right: 60px;"></div>
          <el-slider v-else class="slider_buy" style="position:absolute;left: 60px;right: 60px;height:22px;" disabled show-tooltip v-model="value_trade" :max="value_total"></el-slider>
          <span style="position:absolute;background-color: #FFE9E9;font-size:12px;right: 0;top:0;color: #FF0000;width: 60px;text-align: center;height:22px;line-height:22px;padding: 0;">{{ $t('robot.selling_order') }} {{ sellOrderList.length }}</span>
        </div>
        <div class="grid_order_list">
          <div style="font-size: 14px;display: flex; justify-content: space-between">
            <span style="display: inline-block;width: 9.5%;text-align: left">{{ $t('robot.number') }}</span>
            <span style="display: inline-block;width: 23%;text-align: left">{{ $t('robot.entrusted_price') }}</span>
            <span style="display: inline-block;width: 30%;text-align: center">{{ $t('robot.trading_up_and_down') }}</span>
            <span style="display: inline-block;width: 23%;text-align: right">{{ $t('robot.entrusted_price') }}</span>
            <span style="display: inline-block;width: 9.5%;text-align: right">{{ $t('robot.number') }}</span>
          </div>
          <div style="margin-top: 8px;position: relative;min-height:300px;overflow-y: auto; -webkit-overflow-scrolling: touch">
            <table v-if="buyOrderList.length != 0" class="table" style="width: 49%; min-height: 160px; overflow-y: scroll;display: inline-block; border-right: 1px #C7C9CB solid;border-color:#EBEEF5"frame=void>
              <tbody style="width: 100%; display: inline-table;">
              <tr class="hang_order" v-for="(item, i) in buyOrderList" style="width: 100%;">
                <td style="width:20%;color: #33B06A;margin-left: -2px">{{ $t('robot.buy') }}{{ i+1 }}</td>
                <td style="text-align: left;width: 40%">{{ item.price }}</td>
                <td style="text-align: right;width: 40%;padding-right: 1.5%;">{{ item.buyPrice | roundEightFilter }}%</td>
              </tr>
              </tbody>
            </table>
            <div v-else-if="buyOrderList.length == 0 && sellOrderList.length != 0" style="display: inline-block; height: 160px;text-align: center;width: 49%;border-right: 1px #C7C9CB solid;font-size:14px;color:#72777E;line-height: 160px">{{ $t('robot.no_data') }}</div>
            <div v-else-if="buyOrderList.length == 0 && sellOrderList.length == 0" style="display: inline-block; height: 160px;text-align: center;width: 49%;border-right: 1px #C7C9CB solid;font-size:14px;color:#72777E;line-height: 160px">{{ $t('robot.data_acquisition_in_progress') }}</div>
            <table v-if="sellOrderList.length != 0" class="table" style="width: 49%;min-height: 160px; overflow-y: scroll; display: inline-block;top: 0;position: absolute;right: 0">
              <tbody style="width: 100%; display: inline-table;">
              <tr class="hang_order" v-for="(item, i) in sellOrderList" style="width: 100%;">
                <td style="text-align: left;width: 40%;padding-left: 1.5%;">{{ item.buyPrice | roundEightFilter }}%</td>
                <td style="text-align:right;padding-right: 0px;width: 40%">{{ item.price }}</td>
                <td style="text-align:right;color:#FF4D4D;width: 20%;">{{ $t('robot.sell') }}{{ i+1 }}</td>
              </tr>
              </tbody>
            </table>
            <div v-else-if="buyOrderList.length != 0 && sellOrderList.length == 0" style="display: inline-block; height: 160px;text-align: center;width: 49%;font-size:14px;color:#72777E;line-height: 160px">{{ $t('robot.no_data') }}</div>
            <div v-else-if="buyOrderList.length == 0 && sellOrderList.length == 0" style="display: inline-block; height: 160px;text-align: center;width: 49%;font-size:14px;color:#72777E;line-height: 160px">{{ $t('robot.data_acquisition_in_progress') }}</div>
          </div>
        </div>
      </div>
      <div v-if="celue_type == 4" class="" style="width: 100%;">
        <div>
          <el-table :data="dealOrderList" style="width: 100%">
            <el-table-column class="mobile_order" align="left" prop="deal_time" :label="$t('robot.transaction_time')">
              <template slot-scope="scope">
                <span style="display: block;">{{ scope.row.year }}</span>
                <span style="display: block;margin-top: -5px">{{ scope.row.time }}</span>
              </template>
            </el-table-column>
            <el-table-column align="center" width="135%" prop="profitFex" :label="$t('robot.grid_profit')+'('+(details.coinTwo)+')'">
              <template slot-scope="scope">
                <span v-if="scope.row.profitFex == '--'" :style="{color: scope.row.profitFex>0?'#52C41A':  scope.row.profitFex<0?'#FF4D4F':'#1E2329'}">{{ scope.row.profitFex }}</span>
                <span v-else :style="{color: scope.row.profitFex>0?'#52C41A':  scope.row.profitFex<0?'#FF4D4F':'#1E2329'}">{{ scope.row.profitFex }}</span>
              </template>
            </el-table-column>
            <el-table-column align="right" prop="earn" :label="$t('robot.operation')">
              <template slot-scope="scope">
                <span style="color: #AB54DB;font-size: 14px;font-weight: 400;cursor: pointer" @click="showDetails(2, scope.row.buy_order_id, scope.row.sell_order_id)">{{ $t('robot.see') }}</span>
              </template>
            </el-table-column>
          </el-table>
          <span style="font-size:14px; color: #000613; line-height: 22px;">{{ $t('robot.total') }} <span>{{ totalRowofMb }}</span> {{ $t('robot.strip') }}</span>
          <el-pagination
              style="float:right;padding-right: 0px;"
              small
              layout="prev, pager, next"
              @current-change="getDealOrderListToPage"
              :page-size="10"
              :current-page="pageNumofMb"
              :hide-on-single-page="totalRowofMb<=0"
              :total="totalRowofMb">
          </el-pagination>
        </div>
      </div>
    </el-card>
    <el-drawer
        :title="$t('robot.transaction_details')"
        :visible.sync="semi_drawer2"
        :direction="direction">
      <div style="width: 100%;height: 1px;background: #EAECEF;margin: 10px 3% 10px 0"></div>
      <div v-for="(item, i) in DealOrderDetails" style="padding: 0 20px 20px 20px; font-size: 14px">
        <span style="display:inline-block;font-weight: 500;font-size:16px;color: #1E2329;width: 32%">{{ items.coin_one }}<span v-if="item.side == 'buy'" style="display:inline-block;vertical-align:middle;font-size: 18px;margin-top:0px;color: #52C41A;">⇀</span><span v-if="item.side == 'sell'" style="display:inline-block;vertical-align:middle;font-size: 18px;margin-top:0px;color: #FF4D4F;">↼</span>{{ items.coin_two }}</span>
        <div style="display:inline-block;margin-left: 2px">
          <span v-if="item.side == 'buy'" style="padding: 2px 5px;border-radius:2px;background-color: #ecf8f5;font-weight:0;color:#49b18e;width: fit-content">{{ $t('robot.buy_in') }}</span>
          <span v-else style="padding: 2px 5px;border-radius:2px;background-color: #fcf6f5;font-weight:0;color:#e56980;width: fit-content">{{ $t('robot.sell_out') }}</span>
        </div>
        <div style="margin-top: 5px;color: #72777E;font-weight: unset">
          <div style="display: block">
            <span style="display:inline-block;width:50%;">{{ $t('robot.entrusted_time') }}<span style="display: block;color: #1E2329;font-weight: 500">{{ item.create_time }}</span></span>
            <span style="display:inline-block;width:50%;margin-top: 5px">{{ $t('robot.transaction_time') }}<span style="display: block;color: #1E2329;font-weight: 500">{{ item.deal_time }}</span></span>
          </div>
          <div style="display: block">
            <span style="display:inline-block;width: 50%">{{ $t('robot.transaction_volume') }}({{items.coin_one}})<span style="display: block;color: #1E2329;font-weight: 500">{{item.total_true | roundEightFilter}}</span></span>
            <span style="display:inline-block;width:50%;margin-top: 5px">{{ $t('robot.average_transaction_price') }}({{items.coin_one}})<span style="display: block;color: #1E2329;font-weight: 500">{{ item.avg_price | roundEightFilter }}</span></span>
          </div>
          <div style="display: block">
            <span style="display:inline-block;width: 50%">{{ $t('robot.number_of_transactions') }}({{items.coin_two}})<span style="display: block;color: #1E2329;font-weight: 500">{{ item.size | roundEightFilter }}</span></span>
            <span style="display:inline-block;width:50%;margin-top: 5px">{{ $t('robot.service_charge') }}({{item.side == 'buy'?items.coin_two:items.coin_one}})<span style="display: block;color: #1E2329;font-weight: 500">-{{item.fee_true | roundEightFilter}}</span></span>
          </div>
        </div>
      </div>
    </el-drawer>
  </div>

    <el-dialog
        class="details_dialog"
        :title="robotState != 2? $t('robot.are_you_sure_del_grid'): $t('robot.del_grid_strategy')"
        :visible.sync="dialogStopState"
        :close-on-click-modal="true"
        :close-on-press-escape="true"
        width="600px"
        style="height: auto;overflow: unset;padding-top: 0px">
      <el-divider></el-divider>
      <span v-if="robotState != 2" class="del_grid_span" style="display:inline-block;background-color: #F4F8FF;color: #AB54DB; padding: 10px">{{ $t('robot.del_grid_tip') }}</span>
<!--      <span v-else style="display:inline-block;margin-top: 10px">{{ $t('robot.del_grid_tip') }}</span>-->
      <div v-if="robotState != 2" class="" style="margin-top: 30px">
        <el-radio style="display: block" v-model="stopState" label="3">{{ $t('robot.delete')+ $t('robot.strategy_cancel_order')}}</el-radio>
        <span style="display:block;color: #8E9298;margin-left: 23px;margin-top: 3px">{{ $t('robot.strategy_cancel_order_radio1') }}</span>
        <span v-if="stopState == '3'" style="display:block;color: #FF4D4F;margin-left: 23px;margin-top: 3px">{{ $t('robot.strategy_cancel_order_radio2_tip') }}</span>
      </div>
      <div v-if="robotState != 2" class="" style="margin-top: 20px">
        <el-radio v-model="stopState" label="2">{{ $t('robot.delete')+ $t('robot.strategy_no_cancellation')}}</el-radio>
        <span style="display:block;color: #8E9298;margin-left: 23px;margin-top: 3px">{{ $t('robot.strategy_no_cancellation_radio1') }}</span>
      </div>
      <span v-if="robotState == 2" style="display:block;color: #606266;margin-top: 6px;">{{ $t('robot.del_grid_tip_stop') }}</span>
      <span slot="footer" class="dialog-footer">
    <el-button class="robot_btn" type="" style="width: 90px;color: #72777E !important;" @click="dialogStopState = false">{{ $t('robot.cancel') }}</el-button>
    <el-button class="go_and_open" type="primary" style="width: 90px;text-align: center" @click="delGrid()">{{ $t('robot.confirm') }}</el-button>
  </span>
    </el-dialog>
  </div>
</template>

<script>
import ChartsLine from "./chartsLine";
import {
  delBb, delGrid,
  delSj, delUb,
  getDealOrderDetails,
  getDealOrderList,
  getGridStrategy,
  getNoDealSellOrderList,
  getTickerPrice,
  startOrPauseGridStrategy
} from "../../server/robot";
import tooltip from "../common/tooltip";

export default {
  name: "Robot_semi_detail",
  components: {ChartsLine, tooltip},
  data(){
    return{
      isMobile: false,
      value_trade: 0,
      value_total: 0,
      // dialogMartinGridDetails: false,
      dialogStopState: false,
      delGridItem: {}, //刪除網格臨時存儲
      stopState: '3', // 2:不撤單  3：撤單
      DealOrderDetails: [],
      orderId: '',
      switchValue: 2,
      celue_type: '1',
      semi_drawer2: false,
      direction: 'btt',
      items: {},
      runTime: '',
      details:{},
      buyOrderList: [],
      sellOrderList: [],
      details2: '',
      robotType: '',
      robotState: '',
      dealOrderList: [],
      capitalListTotal: 0,
      pageNum: 1,
      totalRow: 0,
      totalRowofMb: 0,
      pageNumofMb: 1,
      requestTimes: 0,
      t:null,

    }
  },

  mounted() {
    this.orderId = this.$route.query.id
    this.switchValue = this.$route.query.switchValue;
    this.items = sessionStorage.getItem('detailItem')?JSON.parse(sessionStorage.getItem('detailItem')):{}
    this.robotType = this.$route.query.robotType
    this.robotState = this.$route.query.robotState
    this.getRuntime();
    if (this.$util._isMobile()){
      if (this.$util._isMobile[0] == 'iPad'){
        this.isMobile = false
      }
      this.isMobile = true;
    } else {
      this.isMobile = false
    }
    this.changeScreenSize();
    this.initData();
    if (this.items.state <= 1) {
      this.setTimers()
    }
  },

  methods:{
    initData(){
      this.getGridStrategy();
      this.getTickerPrice();
      if (this.pageNumofMb == 1 || this.pageNum == 1) {
      this.getDealOrderList(this.isMobile?this.pageNumofMb:this.pageNum);
      }
    },

    changeScreenSize(){
      window.addEventListener('resize',()=>{
        const bodyWidth=document.body.clientWidth
        if (bodyWidth>=900) {
          this.isMobile = false
          return
        }
        this.isMobile=true
      })
      const bodyWidth=document.body.clientWidth
      if (bodyWidth>=999) return this.isMobile = false
    },

    celueType(){

    },

    getRuntime(){
      this.runTime = this.$util.formatDuring(new Date().getTime() - new Date(this.items.add_time))
    },

    startAndStopThis(item, num){
      if(num == 1){
        this.startOrPauseGridStrategy(item.strategy_uid, num);
      }else {
        this.$confirm(this.$t('robot.you_sure_you_suspend_strategy_grid'))
            .then(async _ => {
              await this.startOrPauseGridStrategy(item.strategy_uid, num);
            })
            .catch(_ => {});
      }

    },
    async startOrPauseGridStrategy(uid, action) {
      const res = await startOrPauseGridStrategy(uid, action)
      if (!res) return;
      if (res.success) {
        if (action == 1) {
          this.$message.success(this.$t('robot.turned_on'))
          this.items.state = 0
        } else {
          this.$message.success(this.$t('robot.paused'))
          this.items.state = 1
        }
      } else {
        this.$message.error(res.message)
      }
    },

    async getGridStrategy() {
      const res = await getGridStrategy(this.items.strategy_uid, this.requestTimes)
      if (!res) return
      if (res.success){
        this.details = res.data
        this.value_total = this.details.grid_number
        this.details.coinOne = this.details.coin.split('-')[0]
        this.details.coinTwo = this.details.coin.split('-')[1]
      }else {
        this.$message.error(res.message)
      }
    },
    async getNoDealSellOrderList(side){
      const res = await getNoDealSellOrderList(this.items.strategy_uid, side, this.requestTimes)
      if (!res) return
      if (res.success){
        if (side == 'buy'){
          this.buyOrderList = res.data
          this.value_trade = this.buyOrderList.length
          for (let i=0; i< this.buyOrderList.length; i++){
            if(parseFloat(this.buyOrderList[i].price)-parseFloat(this.details2) == 0){
              this.buyOrderList[i].buyPrice = '0.00'
            }else{
              this.buyOrderList[i].buyPrice = (((parseFloat(this.buyOrderList[i].price)-parseFloat(this.details2))/parseFloat(this.details2))*100).toFixed(2)
            }
          }
        } else {
          this.sellOrderList = res.data
          for (let i=0; i< this.sellOrderList.length; i++){
            if (parseFloat(this.sellOrderList[i].price)-parseFloat(this.details2) == 0){
              this.sellOrderList[i].buyPrice = '0.00'
            }else{
              this.sellOrderList[i].buyPrice = (((parseFloat(this.sellOrderList[i].price)-parseFloat(this.details2))/parseFloat(this.details2))*100).toFixed(2)
            }
          }
        }
      }else {
        this.$message.error(res.message)
      }
    },

    async getTickerPrice(){
      const res = await getTickerPrice(this.items.coin, this.robotType, this.requestTimes)
      if (!res) return
      if (res.success){
        if (!res.data) return
        this.details2 = res.data
        await this.getNoDealSellOrderList('buy');
        await this.getNoDealSellOrderList('sell');
      }else {
        this.$message.error(res.message)
      }
    },

    getDealOrderListToPage(num){
      this.requestTimes = 0
      this.getDealOrderList(num)
    },

    async getDealOrderList(num){
      this.pageNumofMb = num;
      this.pageNum = num;
      const res = await getDealOrderList(this.items.strategy_uid, this.isMobile?this.pageNumofMb:this.pageNum, 10, this.requestTimes)
      if (!res) return
      if (res.success){
        if (!res.data) return
        this.totalPageofMb = res.data.totalPage
        this.totalRowofMb = res.data.totalRow
        this.totalRow = res.data.totalPage
        this.capitalListTotal = res.data.totalRow
        this.dealOrderList = res.data.list
        for (let i=0; i<this.dealOrderList.length; i++){
          this.dealOrderList[i].profitFex = this.dealOrderList[i].profit == 0?'--':this.$options.filters['roundEightFilter'](this.dealOrderList[i].profit)
          this.dealOrderList[i].year = (this.dealOrderList[i].deal_time).split(' ')[0]
          this.dealOrderList[i].time = (this.dealOrderList[i].deal_time).split(' ')[1]
        }
      } else {
        this.$message.error(res.message)
      }
    },
    handleRecommendCurrentChange(){

    },
    async showDetails(num,buy_order_id,sell_order_id){
      // if (num == 1) {
      //   this.dialogMartinGridDetails = true
      // } else {
        this.semi_drawer2 = true
      // }
      this.DealOrderDetails = []
      const res = await getDealOrderDetails(this.items.strategy_uid,buy_order_id,sell_order_id);
      // if (!res) return
      if (res.success){
        // if (!res.data) return
        this.DealOrderDetails = res.data
        for (let i=0; i<this.DealOrderDetails.length; i++){
          if (this.DealOrderDetails[i].side == 'sell'){
            this.DealOrderDetails[i].charge = (this.DealOrderDetails[i].price * this.DealOrderDetails[i].size * 0.0008).toFixed(8)
          } else {
            this.DealOrderDetails[i].charge = (this.DealOrderDetails[i].size * 0.0008).toFixed(8)
          }
          this.DealOrderDetails[i].total = (this.DealOrderDetails[i].price*this.DealOrderDetails[i].size).toFixed(2)
          this.DealOrderDetails[i].coinOne = this.DealOrderDetails[i].instrument_id.split('-')[0]
          this.DealOrderDetails[i].coinTwo = this.DealOrderDetails[i].instrument_id.split('-')[1]
          this.DealOrderDetails[i].transactionVolume = (this.DealOrderDetails[i].price*this.DealOrderDetails[i].size).toFixed(2)
        }
          // console.log(this.DealOrderDetails)

      }else {
        this.$message.error(res.message)
      }
    },
    delet(item) {
      this.dialogStopState = true;
      this.delGridItem = item;
      // this.delGrid()
    },

    async delGrid(){
      if (this.robotState == 2){
        this.stopState = '2'
      }
      const res = await delGrid(this.delGridItem.strategy_uid, this.stopState)
      if (res.success) {
        this.$message.success(this.$t('robot.del_success'))
        this.dialogStopState = false;
        await this.getGridStrategy();
        this.$router.back()
        // await this.getSj();
        // await this.getSymbolCount();
      } else {
        this.dialogStopState = false;
        this.$message.error(res.message)
      }
      // this.$confirm('確定刪除這條網格策略嗎？')
      //     .then(async _ => {
      //
      //     })
      //     .catch(_ => {});
    },

    setTimers(){
      this.t = setInterval(()=>{
        this.requestTimes = 10;
        this.initData()
      },30000)
    },

  },

  destroyed() {
    clearInterval(this.t)
  },
  beforeDestroy() {
    clearInterval(this.t);
    this.t = null;
  }


}
</script>

<style scoped>

/*.el-card{*/
/*  margin-bottom: 12px;*/
/*}*/

.detail_title {
  position: relative;
}

.detail_title img:nth-of-type(1) {
  width: 24px;
  height: 24px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 7px;
}

.detail_title span:nth-of-type(1) {
  font-size: 16px;
  font-weight: 600;
  display: inline-block;
  color: black;
  margin-top: 2px;
  vertical-align: middle;
}

.detail_title span:nth-of-type(2) {
  font-size: 14px;
  font-family: PingFangTC-Regular, PingFangTC;
  font-weight: 400;
  display: inline-block;
  color: #AB54DB;
  vertical-align: middle;
  background: #F4F8FF;
  padding: 6px 12px;
  border-radius: 2px;
  margin-left: 9px;
}

.detail_title img:nth-of-type(2) {
  width: 14px;
  height: 14px;
  margin-left: 24px;
  vertical-align: middle;
}

.detail_title span:nth-of-type(3) {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #72777E;
  margin-left: 12px;
  vertical-align: middle;
}

.detail_title div {
  display: inline-block;
  position: absolute;
  right: 0px;
}

.detail_title_button_w {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #AB54DB !important;
  padding: 9px 17px;
  background: #FFFFFF;
  border-radius: 2px;
  border: 1px solid #AB54DB;
}

.detail_title_button_b {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  padding: 10px 18px;
  background: #AB54DB;
  border-radius: 2px;
  border: unset;
}

.total_revenue {

}

.total_revenue_content {
  vertical-align: top;
  width: 49%;
  display: inline-block;
}

.total_revenue_content div:nth-of-type(1)>div {
  display: inline-block;
  width: 48.5%;
}

.total_revenue_content div:nth-of-type(1)>div:nth-of-type(2) {
  text-align: right;
  display: inline-block;
  padding-right: 3%;
}

.total_revenue_content span {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #72777E;
  display: block;
}

.total_revenue_content_bottom{
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #1E2329;
}

.celue_detail_base_set{
  margin-top: 23px;
}

.celue_detail_base_set span{
  display: inline-block;
}

.grid_order span{
  color: #72777E;
  font-size: 14px;
}

.side_color_red {
  color: #FF4D4D
}

.side_color_green {
  color: #52C41A
}

.grid_order_list{
  margin-top: 70px;
}

.grid_order_list table td {
  width: 33%;
  color: #1E2329;
  font-size: 14px;
  padding-top: 2px;
  padding-bottom: 2px;
}

>>>.el-tabs__nav-wrap::after{
  height: 1px;
}

>>>.el-tabs__item.is-active {
  color: #AB54DB;
}

>>>.el-tabs__active-bar{
  background-color: #AB54DB;
}

>>>.el-slider__runway {
  height: 25px;
  background-color: #FFE9E9;
  margin: 0;
  z-index: 10;
  border-radius: 0;
}

>>>.el-slider__runway.disabled .el-slider__bar {
  background-color: #EAF8E3;
  z-index: 99999;
  height: 25px;
  border-radius: 0;
}

>>>.el-slider__button {
  width: 0px;
  height: 0px;
  border: unset;
  background-color: transparent;
  border-radius: unset;
}

>>>.el-table::before {
  left: 0;
  bottom: 0;
  width: 0;
  height: 0px;
  background-color: unset;
}

@media screen and (max-width: 900px) {
  .robot-pc {
    display: none;
  }

  >>>.el-card__body {
    border-bottom: unset !important;
  }
  >>>.el-tabs__item {
    padding: 0 9px;
  }
  >>>.el-slider__runway {
    height: 22px;
    background-color: #FFE9E9;
    z-index: 1;
    margin: 0;
    border-radius: 0;
  }

  >>>.el-slider__runway.disabled .el-slider__bar {
    background-color: #EAF8E3;
    z-index: 99999;
    height: 22px;
    border-radius: 0;
    background-attachment:fixed;
  }

  >>>.el-drawer__header{
    color: #1E2329;
  }

  >>>.el-drawer.btt{
    border-radius: 10px 10px 0px 0;
    height: unset !important;
    right: 0;
    bottom: 0 !important;
    width: 100%;
  }

  .del_grid_span{
    width: unset;
  }

  .grid_order_list{
    margin-top: 40px;
  }

  >>>.el-table .el-table__cell{
    padding: 5px 0;
  }

  .hang_order td{
    font-size: 13px !important;
  }
}

@media screen and (min-width: 900px) {
  .robot-pc {
    display: block;
  }
  .details_pc >>>.el-divider {
    margin-top: -20px !important;
  }

  .details_dialog >>>.el-dialog__body{
    /*margin-top: -40px;*/
    padding: 0 20px 30px 20px;
  }

  .del_grid_span{
    width: 540px;
  }
}

.title-array {
  font-family: unset !important;
  font-weight: unset !important;
  display: block !important;
  vertical-align: middle !important;
  background: unset !important;
  padding: 0 !important;
  border-radius: unset !important;
  margin-left: 0 !important;
}

::-webkit-scrollbar {
width: 3px;
}

/*定义滚动条轨道 内阴影+圆角*/
::-webkit-scrollbar-track {
  border-radius: 10px;
  /*background-color: rgba(0, 0, 0, 0.1);*/
  background-color: transparent;
}

/*定义滑块 内阴影+圆角*/
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(26, 25, 25, 0.3);
  background-color: rgba(0, 0, 0, 0.1);
}

>>>.el-radio__inner {
  width: 16px;
  height: 16px;
}
>>>.el-radio__input.is-checked+.el-radio__label {
  color: #1E2329;
}
>>>.el-radio__label{
  color: #1E2329;
}
</style>
