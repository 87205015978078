<template>

  <div style="display:block;height: 170px;vertical-align: top">
    <div style="display:block;height: 170px" id="chart_line"></div>
  </div>

</template>

<script>
// import * as echarts from "echarts";

export default {
  name: "chartsLine",
  data(){
    return{

    }
  },

  mounted() {
    let chart_line = this.$echarts.init(document.getElementById('chart_line'))
    this.chartLine(chart_line)
  },

  methods:{
    chartLine(trendChart) {
      let base = +new Date(2016, 9, 3);
      let oneDay = 24 * 3600 * 1000;
      let valueBase = Math.random() * 300;
      let data = [];
      for (var i = 1; i < 10; i++) {
        var now = new Date((base += oneDay));
        var dayStr = [now.getFullYear(), now.getMonth() + 1, now.getDate()].join('-');
        valueBase = Math.round((Math.random() - 0.5) * 20 + valueBase);
        valueBase <= 0 && (valueBase = Math.random() * 300);
        data.push([dayStr, valueBase]);
      }
      const option = {
        legend: {
          top: 'top',
          data: ['Intention']
        },
        // tooltip: {
        //   triggerOn: 'none',
        //   position: function (pt) {
        //     return [pt[0], 130];
        //   }
        // },

        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            crossStyle: {
              color: '#999'
            },
            label:{
              formatter:function (params) {
                if (params.axisIndex===0&&params.axisDimension!=='x') {
                  params.value = Number(params.value).toFixed(2)
                }
                if (params.axisIndex===1&&params.axisDimension!=='x') params.value=(params.value*100).toFixed(2)+'%'
                return  params.value;
              }
            }
          },
          // formatter:params=>{
          //   var relVal = `<span style="color: #1E2329;font-weight: 600">${params[0].name}</span>`
          //   for (var i = 0, l = params.length; i < l; i++) {
          //     if (i===0) relVal += '<br/>' + `<span style="display: inline-block;margin-top: 6px">${params[i].marker + params[i].seriesName + ' : ' + params[i].value.toFixed(2)+'USDT'}</span>`
          //     else relVal += '<br/>' + `<span style="display: inline-block;margin-top: 4px">${params[i].marker + params[i].seriesName + ' : ' + (params[i].value*100).toFixed(2) + '%'}</span>`
          //   }
          //   return relVal;
          // },
        },

        xAxis: {
          type: 'time',
          axisPointer: {
            value: '2016-10-7',
            snap: true,
            lineStyle: {
              color: '#7581BD',
              width: 2
            },
            label: {
              show: true,
              formatter: function (params) {
                return params.value.toString();
              },
              backgroundColor: '#7581BD'
            },
            handle: {
              show: false,
              color: '#7581BD'
            }
          },
          splitLine: {
            show: false
          }
        },
        yAxis: {
          type: 'value',
          axisTick: {
            inside: true
          },
          splitLine: {
            show: false
          },
          axisLabel: {
            inside: false,
            formatter: '{value}'
          },
          z: 10
        },
        grid: {
          top: -10,
          left: 35,
          right: 15,
          height: 160
        },
        series: [
          {
            name: 'Fake Data',
            type: 'line',
            smooth: true,
            symbol: 'circle',
            symbolSize: 5,
            sampling: 'average',
            itemStyle: {
              color: '#0770FF'
            },
            stack: 'a',
            areaStyle: {
              color: {
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: 'rgba(58,77,233,0.9)'
                  },
                  {
                    offset: 1,
                    color: 'rgba(58,77,233,0.1)'
                  }
                ]
              },
            },
            data: data
          },
        ]
      };
      option && trendChart.setOption(option);
    },
  }
}
</script>

<style scoped>

</style>
